.c-rank-users-component {
  background-color: #fff;
}

.c-rank-users-component + .c-rank-users-component {
  margin-top: 0.08rem;
}

.c-rank-users-component .label {
  font-size: 0.26rem;
  padding: 0 0.3rem;
  height: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.c-rank-users-component .label:nth-child(2) {
  height: 1.64rem;
  margin-left: -0.3rem;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 0.1rem;
}

.c-rank-users-component .label h6 {
  font-size: 0.3rem;
  color: #333;
}

.c-rank-users-component .label .label-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-rank-users-component .label .label-more span {
  margin: 0 0.05rem;
  color: #999;
  font-size: 0.24rem;
}

.c-rank-users-component .user-list {
  overflow: hidden;
  font-size: 0.24rem;
  width: -webkit-calc(20% -  0.3rem);
  width: calc(20% -  0.3rem);
  margin-left: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.c-rank-users-component .user-list .avatar {
  width: 1rem;
  height: 1rem;
}

.c-rank-users-component .user-list .avatar span {
  color: #666;
  width: 100%;
  font-size: 0.24rem;
}

.c-rank-users-component .user-list .avatar img {
  width: 1rem;
  height: 1rem;
}

.c-rank-users-component .user-list .user-name {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin-top: 0.1rem;
  max-width: 4em;
}

.c-rank-users-component .m-svg-small {
  color: #ccc;
  width: 0.3rem;
  height: 0.3rem;
}