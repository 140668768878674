.rankItem-info {
  width: 90%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.rankItem-user-avatar {
  width: 0.76rem;
  height: 0.76rem;
  margin: 0;
}

.rankItem-title {
  margin-left: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rankItem-title h6 {
  margin: 0;
  font-size: 0.3rem;
  color: #333;
}

.rankItem-title p {
  color: #999;
  font-size: 0.28rem;
  margin: 0.1rem 0 0;
}