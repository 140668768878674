.common-header {
  position: fixed;
  top: 0;
}

.rank-nav {
  position: fixed;
  z-index: 100;
  top: 0.9rem;
  padding-top: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.9rem;
  width: 100%;
  max-width: 7.68rem;
  line-height: 0.89rem;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.rank-nav-item {
  padding: 0 0.1rem;
  font-size: 0.32rem;
  color: #999;
  border-bottom: 0.03rem solid transparent;
}

.rank-nav-item + .rank-nav-item {
  margin-left: 0.9rem;
}

.rank-nav-item.router-link-active {
  border-color: #59b6d7;
  color: #333;
}

.rank-content {
  padding-top: 1.8rem;
  min-height: 100vh;
  position: relative;
  background-color: #f4f5f5;
}